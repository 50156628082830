

















import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'

@Component({
  components: {
    SelectBox
  }
})
export default class Step1 extends Vue {
  private selected = ''

  private updateVal (ev: string): void {
    this.selected = ev
    this.$store.dispatch('walkthrough/setHaveFreeTime', this.selected)
  }

  private mounted (): void {
    const previouslySelectedValue = this.$store.getters['walkthrough/getHaveFreeTime']
    if (previouslySelectedValue) {
      (this.$refs.selectBox as SelectBox).setVal('Yes')
    }
    this.selected = previouslySelectedValue
  }

  private goTo (): void {
    this.$router.push('2')
  }
}
