















import { Component, Vue } from 'vue-property-decorator'

@Component

export default class Step1 extends Vue {
  private goTo (): void {
    this.$router.push('2')
  }
}
