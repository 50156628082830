
































import { Component, Vue, Watch } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
@Component({
  components: {
    Player,
    HighlightText,
    ContentArea,
    Title,
    ProgressLine
  }
})
export default class Step1 extends Vue {
 private isText = false
 private path = 0
 private videoIsEnd (): void {
   this.isText = true
 }

 private goTo (path:string):void {
   this.$router.push(path)
 }

 private mounted (): void {
   if (window.innerWidth < 768) {
     this.$root.$on('mobile-go-back', () => {
       this.goBack()
     })
   }
 }

 get videoUrls (): Array<string> {
   return this.$store.state.video.videoUrls.slice(2, 4)
 }

 private goBack (): void {
   if (this.path === 1) this.path = 0
   else this.$router.push('pre-test/3')
 }

 private destroyed (): void {
   if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
 }

 @Watch('path')
 onPathChange ():void {
   this.isText = false
 }
}
